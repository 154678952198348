import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Tab, TabProps } from '@mui/base'
import { styled } from '@mui/material'
import useGetTabTemplate from '~/src/components/mui-wrappers/styled/ordering-methods/tab/styles/useGetTabTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR } from '~/src/stores/firms'

const OrderingMethodTab = styled(({ value, ...otherProps }: TabProps) => {
  const firmsWithPickupMethod = useEoValue(LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR(value as OrderingMethodNames))

  if (!firmsWithPickupMethod.length) {
    return null
  }

  return <Tab value={value} {...otherProps} />
})(() => useGetTabTemplate().getComponentSx())

export default OrderingMethodTab
