import { useEffect, useState } from 'react'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface ReturnsType {
  height: number,
  getHeight(): number,
}

const useGetMobileNavigationHeight = (): ReturnsType => {
  const layout = useEoValue(LAYOUT_STATE)
  const { height: mobileNavigationHeight } = useGetDOMNodeProperties('layout__mobile__navigation_bottom_navigation')
  const [ height, setHeight ] = useState<number>(0)

  const getHeight = (): number => {
    if (!layout.mobileNavigation.display) {
      return 0
    }

    return mobileNavigationHeight
  }

  useEffect(() => {
    setHeight(getHeight())
  }, [
    mobileNavigationHeight,
    layout.mobileNavigation.display
  ])

  return {
    height,
    getHeight
  }
}

export default useGetMobileNavigationHeight
