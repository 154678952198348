import { Box, Typography } from '@mui/material'
import LocatorCurrentFirmLogo from '~/src/components/locator/locator-current-firm-logo'
import LocatorAddressFilter from '~/src/components/locator/locator-filters/locator-address-filter'
import useGetFirmsLocatorTemplate from '~/src/components/locator/locator-filters/styles/useGetFirmsLocatorTemplate'
import LocatorFirmTitle from '~/src/components/locator/locator-firm-title'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { BRAND_STATE } from '~/src/stores/brand'

const LocatorFilters = () => {
  const brand = useEoValue(BRAND_STATE)
  const layout = useEoValue(LAYOUT_STATE)
  const styles = useGetFirmsLocatorTemplate()
  const { t } = useTranslations()

  return (
    <Box
      id='firms_locator_component'
      sx={styles.getComponentSx()}
    >
      <Box sx={styles.getLogoTitleAndHelperTextContainerSx()}>
        {!layout.header.display && (
          <Box sx={styles.getLogoContainerSx()}>
            <LocatorCurrentFirmLogo sx={styles.getCurrentFirmSx()} />
          </Box>
        )}

        <LocatorFirmTitle />

        <Typography
          variant='body1'
          id='firms__locator__helper_text'
          sx={styles.getHelperTextSx(!!brand?.assets.shopImage)}
        >
          {t('locator.enterYourAddressText')}
        </Typography>
      </Box>

      <Box
        id='firms__locator__filters_container'
        sx={styles.getFiltersContainerSx()}
      >
        <LocatorAddressFilter />
      </Box>
    </Box>
  )
}

export default LocatorFilters
