import getLocatorOrderingMethodTemplateService
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/GetLocatorOrderingMethodTemplateService'
import StylesLocatorOrderingMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorOrderingMethodServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorOrderingMethodTemplate = (): StylesLocatorOrderingMethodServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorOrderingMethodTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorOrderingMethodTemplate
