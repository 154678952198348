import { Firm } from '@eo-storefronts/eo-core'
import LocatorMapMarkerContainer from '~/src/components/locator/locator-map-container/locator-map-marker-container'
import LocatorMapPopoverContent from '~/src/components/locator/locator-map-container/locator-map-popover-content'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOCATOR_FILTERED_FIRMS_SELECTOR } from '~/src/stores/firms'
import { LAYOUT_STATE } from '~/src/stores/layout'
import GoogleMap from '~/src/components/map/GoogleMap'
import { LANGUAGE_STATE } from '~/src/stores/lang'

const LocatorMap = () => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const language = useEoValue(LANGUAGE_STATE)
  const firms = useEoValue<Firm[]>(LOCATOR_FILTERED_FIRMS_SELECTOR)

  return (
    <GoogleMap
      id='locator__map__google_map'
      geocodes={firms.map((firm: Firm) => firm.address.geoCode)}
    >
      {firms.map((firm: Firm, index: number) => (
        <LocatorMapMarkerContainer
          key={index}
          position={{
            lat: Number(firm.address.geoCode.lat),
            lng: Number(firm.address.geoCode.long)
          }}
          title={firm.name[language] ?? ''}
          enablePopover={!isMobile}
        >
          <LocatorMapPopoverContent firm={firm} />
        </LocatorMapMarkerContainer>
      ))}
    </GoogleMap>
  )
}

export default LocatorMap
