import { Box, Divider, Typography } from '@mui/material'
import OrderingMethodsDateTimeContainer from '~/src/components/ordering-methods/OrderingMethodsDateTimeContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import DeliveryPickupPoints from '~/src/components/ordering-methods/DeliveryPickupPoints'

const LocatorPickupPointSelector = () => {
  const { t } = useTranslations()
  const { firmSelected, isLoading } = useEoValue<LocatorState>(LOCATOR_STATE)

  if (!firmSelected) {
    return null
  }

  return (
    <Box>
      {!!firmSelected?.settings.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints?.length && (
        <>
          <Typography>{t('locator.pickup.pickupPointSelectorLabel')}</Typography>
          <DeliveryPickupPoints
            isLoading={isLoading}
            variant='filled'
            size='normal'
            fullWidth
          />
          <Divider />
        </>
      )}

      <OrderingMethodsDateTimeContainer />
    </Box>
  )
}

export default LocatorPickupPointSelector
