import StylesTabServiceInterface
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab/styles/StylesTabServiceInterface'
import StylesTabServiceStyleOne
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab/styles/StylesTabServiceStyleOne'
import StylesTabServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab/styles/StylesTabServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabServiceStyleTwo(muiTheme)
    default:
      return new StylesTabServiceStyleOne(muiTheme)
  }
}

export default getTabTemplateService
