import { SxProps } from '@mui/material'
import StylesFirmsLocatorServiceInterface
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesFirmsLocatorServiceStyleTwo extends TemplateServiceStyleBase implements StylesFirmsLocatorServiceInterface {
  public getComponentSx(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      height: 'inherit',
      px: 2,
      py: 2,
      width: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: 0,
        px: 2,
        pb: 2
      }
    }
  }

  public getCurrentFirmSx(): SxProps {
    return {
      maxHeight: '6rem',
      maxWidth: '100%',
      objectFit: 'contain',
      objectPosition: 'left'
    }
  }

  public getLogoTitleAndHelperTextContainerSx(): SxProps {
    return {
      flex: '1 1 100%',
      gap: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }

  public getLogoContainerSx(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      pt: 'var(--eo-safe-area-top)',
      [this.muiTheme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center'
      }
    }
  }

  public getHelperTextSx(hasImage: boolean): SxProps {
    return {
      color: hasImage ? 'white' : 'background.contrastText'
    }
  }

  public getFiltersContainerSx(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      mt: 2,
      gap: 1,
      '& > *': {
        backgroundColor: 'transparent !important'
      }
    }
  }
}
