import { PlatformUtils } from '@eo-storefronts/eo-core'
import { paperClasses, SxProps } from '@mui/material'
import StylesLocatorShowOrderingMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorShowOrderingMethodsFirstServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorShowOrderingMethodsFirstServiceInterface {
  public getContainerSx(headerHeight: number): SxProps {
    return {
      height: `calc(var(--page-height) - ${headerHeight}px)`,
      boxShadow: '14px 0px 13px 8px rgba(0,0,0,0.1)',
      [this.muiTheme.breakpoints.down('md')]: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 20
      },
      'img#brand_shop_image': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        filter: 'blur(15px)',
        transform: 'scale(1.10)'
      }
    }
  }

  public getInnerContainerSx(): SxProps {
    return {
      position: 'absolute',
      top: '82px',
      left: 0,
      px: 4,
      pt: PlatformUtils().isMobile ? 'var(--eo-safe-area-top)' : 4,
      height: 'calc(var(--page-height) - 82px)',
      width: '50%',
      overflow: 'auto',
      display: 'grid',
      gridTemplateRows: 'minmax(max-content, 1fr) max-content 1fr',
      gap: 4,
      zIndex: 2,
      [`.${paperClasses.root}`]: {
        width: '100%'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2,
        pt: PlatformUtils().isMobile ? 'var(--eo-safe-area-top)' : 2
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '100%'
      }
    }
  }

  public getTopSectionSx(): SxProps {
    return {
      alignSelf: 'end',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      pl: 2,
      width: '100%',
      div: {
        display: 'none'
      }
    }
  }

  public getBottomSectionSx(): SxProps {
    return {}
  }

  public getLocatorCurrentFirmLogo(): SxProps {
    return {
      width: '4rem',
      mb: 2
    }
  }

  public getComponentSx(): Record<string, unknown> {
    return {}
  }
}
