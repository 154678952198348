import { Firm, OrderingMethodNames } from '@eo-storefronts/eo-core'
import filtersMethods, { FilterMethods } from '~/src/helpers/filtersMethods'
import { createSelector, createSelectorFamily, createState } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

export interface FilterState {
  type: keyof FilterMethods,
  value: unknown,
}

export const LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR = createSelectorFamily<Firm[], OrderingMethodNames>({
  key: 'firmsWithOrderingMethods',
  get: (orderingMethod: OrderingMethodNames) => ({ get }): Firm[] => {
    const firm: Firm | null = get(BRAND_STATE)

    if (!firm?.locations) {
      return []
    }

    return firm.locations.filter((firm: Firm) => Object.keys(firm.settings.orderingMethods).includes(orderingMethod))
  }
})

export const LOCATOR_FILTERS_STATE = createState<FilterState[]>({
  key: 'locatorFiltersState',
  default: [{
    type: 'orderingMethods',
    value: 'pickup'
  }]
})

export const LOCATOR_FILTERED_FIRMS_SELECTOR = createSelector({
  key: 'locatorFilteredFirmsSelector',
  get: ({ get }) => {
    const filters = get<FilterState[]>(LOCATOR_FILTERS_STATE)
    let locations = get(BRAND_STATE)?.locations

    if (!locations?.length) {
      return []
    }

    if (!filters?.length) {
      return locations
    }

    filters.forEach((filter: FilterState) => {
      locations = filtersMethods[filter.type](locations || [], filter.value as any)
    })

    return locations
  }
})

export const LOCATOR_INPUT_FILTER_SELECTOR = createSelectorFamily({
  key: 'locatorInputFilterSelector',
  get: (type: keyof FilterMethods) => ({ get }) => {
    const filters = get<FilterState[]>(LOCATOR_FILTERS_STATE)

    if (!filters.length) {
      return
    }

    return filters.find((filter: FilterState) => filter.type === type)
  }
})

export const setFilters = (filters: FilterState[], value: FilterState): FilterState[] => {
  if (!filters.length) {
    return [
      ...filters,
      value
    ]
  }

  const index: number = filters.findIndex((filter: FilterState) => filter.type === value.type)
  const tmpFilters: FilterState[] = [ ...filters ]

  if (index !== -1) {
    tmpFilters.splice(index, 1, value)
  } else {
    tmpFilters.push(value)
  }

  return tmpFilters
}
