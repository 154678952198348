import { Firm } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useState } from 'react'
import useGetLocatorFirmCardImageTemplate
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/useGetLocatorFirmCardImageTemplate'
import { Constants } from '~/src/helpers/constants'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { useEoValue } from '~/src/hooks/useEoState'

interface Props {
  firm: Firm,
}

const LocatorFirmCard = ({ firm }: Props) => {
  const language = useEoValue(LANGUAGE_STATE)
  const styles = useGetLocatorFirmCardImageTemplate()
  const [ src, setSrc ] = useState<string>(firm.assets.shopImage)

  const handleImageOnError = () => {
    setSrc(Constants.TRANSPARENT_IMG_SRC)
  }

  return (
    <Box
      className='locator__firm__card__image'
      sx={styles.getImageSxProps()}
      component='img'
      src={src}
      alt={firm.name[language] ?? src}
      onError={handleImageOnError}
    />
  )
}

export default LocatorFirmCard
