import { useLayoutEffect, useRef } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE } from '~/src/stores/google-maps-api'

type googleMapsLibraryType = | google.maps.CoreLibrary
| google.maps.MapsLibrary
| google.maps.Maps3DLibrary
| google.maps.PlacesLibrary
| google.maps.GeocodingLibrary
| google.maps.RoutesLibrary
| google.maps.MarkerLibrary
| google.maps.GeometryLibrary
| google.maps.ElevationLibrary
| google.maps.StreetViewLibrary
| google.maps.JourneySharingLibrary
| google.maps.DrawingLibrary
| google.maps.VisualizationLibrary

const useGoogleMapsGeocoderService = (): google.maps.Geocoder | null => {
  const { isLoaded } = useEoValue(GOOGLE_MAPS_API_STATE)
  const geocoderService = useRef<google.maps.Geocoder | null>(null)

  useLayoutEffect(() => {
    if (!isLoaded || geocoderService.current) {
      return
    }

    google.maps.importLibrary('geocoding').then(
      (importedLibrary: googleMapsLibraryType) => {
        if (!('Geocoder' in importedLibrary)) {
          return
        }

        geocoderService.current = new (importedLibrary as google.maps.GeocodingLibrary).Geocoder()
      })
  }, [ isLoaded ])

  return geocoderService.current
}

export default useGoogleMapsGeocoderService
