import { Box } from '@mui/material'
import LocatorLeftContainerDefault from '~/src/components/locator/locator-left-container/locator-left-container-default'
import useGetLocatorLeftContainerTemplate
  from '~/src/components/locator/locator-left-container/styles/useGetLocatorLeftContainerTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LocatorThemeFlowEnum } from '@eo-storefronts/eo-core'
import LocatorShowOrderingMethodsFirst
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first'

const LocatorLeftContainer = () => {
  const styles = useGetLocatorLeftContainerTemplate()
  const firm = useEoValue(FIRM_SELECTOR)

  return (
    <Box
      id='locator__left_container'
      sx={styles.getLeftContainerSx()}>
      {
        firm?.settings.theme.flow?.locator === LocatorThemeFlowEnum.LIST
          ? <LocatorLeftContainerDefault/>
          : <LocatorShowOrderingMethodsFirst/>
      }
    </Box>
  )
}

export default LocatorLeftContainer
