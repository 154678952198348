import StylesLocatorOrderingMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorOrderingMethodsServiceInterface'
import StylesLocatorOrderingMethodsServiceStyleOne
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorOrderingMethodsServiceStyleOne'
import StylesLocatorOrderingMethodsServiceStyleTwo
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorOrderingMethodsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorOrderingMethodsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorOrderingMethodsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorOrderingMethodsServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorOrderingMethodsServiceStyleOne(muiTheme)
  }
}

export default getLocatorOrderingMethodsTemplateService
