import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { SyntheticEvent, useEffect } from 'react'
import LocatorDeliveryTab from '~/src/components/locator/locator-ordering-methods-container/locator-delivery-tab-content'
import LocatorPickupTab from '~/src/components/locator/locator-ordering-methods-container/locator-pickup-tab-content'
import OrderingMethodTab from '~/src/components/mui-wrappers/styled/ordering-methods/tab'
import OrderingMethodTabList from '~/src/components/mui-wrappers/styled/ordering-methods/tab-list'
import OrderingMethodTabPanel from '~/src/components/mui-wrappers/styled/ordering-methods/tab-panel'
import OrderingMethodsTabs from '~/src/components/mui-wrappers/styled/ordering-methods/tabs'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, checkoutFormInitialState, CheckoutFormState } from '~/src/stores/checkout'
import { LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR } from '~/src/stores/firms'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'

const LocatorOrderingMethodsContainer = () => {
  const { t } = useTranslations()
  const setLocatorState = useSetEoState<LocatorState>(LOCATOR_STATE)
  const [ checkoutForm, setCheckoutForm ] = useEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)

  //TODO replace by locationsWithOrderingMethodSelector when brand locations gonna be updated
  const firmsWithPickupMethod = useEoValue(LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR(OrderingMethodNames.PICKUP))

  const handleOnTabChange = (event: SyntheticEvent | null, value: string | number | null) => {
    const defaultCheckoutState: CheckoutFormState = checkoutFormInitialState()

    setLocatorState({ firmSelected: undefined, isLoading: false })
    setCheckoutForm({
      ...defaultCheckoutState,
      orderingMethod: {
        ...defaultCheckoutState.orderingMethod,
        method: value as OrderingMethodNames
      }
    })
  }

  useEffect(() => {
    if (checkoutForm.orderingMethod.method) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      orderingMethod: {
        ...state.orderingMethod,
        method: firmsWithPickupMethod.length ? OrderingMethodNames.PICKUP : OrderingMethodNames.DELIVERY
      }
    }))

    return () => {
      setLocatorState({ firmSelected: undefined, isLoading: false })
    }
  }, [])

  return (
    <OrderingMethodsTabs
      value={checkoutForm.orderingMethod.method as string}
      onChange={handleOnTabChange}
    >
      <OrderingMethodTabList>
        <OrderingMethodTab value={OrderingMethodNames.PICKUP}>
          {t('orderingMethods.pickup')}
        </OrderingMethodTab>
        <OrderingMethodTab value={OrderingMethodNames.DELIVERY}>
          {t('orderingMethods.delivery')}
        </OrderingMethodTab>
      </OrderingMethodTabList>
      <OrderingMethodTabPanel value={OrderingMethodNames.PICKUP}>
        <LocatorPickupTab />
      </OrderingMethodTabPanel>
      <OrderingMethodTabPanel value={OrderingMethodNames.DELIVERY}>
        <LocatorDeliveryTab />
      </OrderingMethodTabPanel>
    </OrderingMethodsTabs>
  )
}

export default LocatorOrderingMethodsContainer
