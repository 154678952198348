import { Box } from '@mui/material'
import LocatorFirmSelector
  from '~/src/components/locator/locator-ordering-methods-container/locator-pickup-tab-content/LocatorFirmSelector'
import LocatorPickupPointSelector
  from '~/src/components/locator/locator-ordering-methods-container/locator-pickup-tab-content/LocatorPickupPointSelector'
import LocatorTabActions from '~/src/components/locator/locator-ordering-methods-container/LocatorTabActions'

const LocatorPickupTabContent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <LocatorFirmSelector />
        <LocatorPickupPointSelector />
      </Box>

      <LocatorTabActions />
    </Box>
  )
}

export default LocatorPickupTabContent
