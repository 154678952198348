import RoutesEnum from '~/src/router/enums/routes.enum'
import { Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import Language from '@eo-storefronts/eo-core/dist/models/Language'

interface ReturnType {
  getHref(firm: Firm): string,
}

const useLocationUrl = (): ReturnType => {
  const lang = useEoValue(LANGUAGE_STATE)

  const getHref = (firm: Firm) => {
    let supportedLanguage = lang

    if (!lang || !firm.languages.some((language: Language) => language.code === lang)) {
      supportedLanguage = firm.defaultLanguage
    }

    return `/${firm.sitename}/${supportedLanguage}${RoutesEnum.MENU}`
  }

  return {
    getHref
  }
}

export default useLocationUrl
