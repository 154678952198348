import StylesLocatorShowOrderingMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceInterface'
import StylesLocatorShowOrderingMethodsFirstServiceStyleOne
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceStyleOne'
import StylesLocatorShowOrderingMethodsFirstServiceStyleTwo
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorShowOrderingMethodsFirstTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorShowOrderingMethodsFirstServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorShowOrderingMethodsFirstServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorShowOrderingMethodsFirstServiceStyleOne(muiTheme)
  }
}

export default getLocatorShowOrderingMethodsFirstTemplateService
