import StylesTabsServiceInterface
  from '~/src/components/mui-wrappers/styled/ordering-methods/tabs/styles/StylesTabsServiceInterface'
import StylesTabsServiceStyleOne
  from '~/src/components/mui-wrappers/styled/ordering-methods/tabs/styles/StylesTabsServiceStyleOne'
import StylesTabsServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/ordering-methods/tabs/styles/StylesTabsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabsServiceStyleTwo(muiTheme)
    default:
      return new StylesTabsServiceStyleOne(muiTheme)
  }
}

export default getTabsTemplateService
