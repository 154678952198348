import { SxProps } from '@mui/material'
import StylesLocatorOrderingMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorOrderingMethodServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorOrderingMethodServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorOrderingMethodServiceInterface {
  public getTabSx(): SxProps {
    return {}
  }
}
