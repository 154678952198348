import { OrderingMethodEnum } from '@eo-storefronts/eo-core'
import { SyntheticEvent, useEffect } from 'react'
import LocatorDeliveryFilterMethod
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method'
import useGetLocatorOrderingMethodsTemplate
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/useGetLocatorOrderingMethodsTemplate'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FilterState, LOCATOR_FILTERS_STATE, LOCATOR_INPUT_FILTER_SELECTOR, setFilters } from '~/src/stores/firms'
import useGetOrderingMethod from '~/src/hooks/ordering-methods/useGetOrderingMethod'
import { EnableableOrderingMethod } from '~/src/types/EnableableOrderingMethod'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  displayDisabledOrderingMethods?: boolean,
}

const LocatorDeliveryFilterMethods = ({ displayDisabledOrderingMethods = false }: Props) => {
  const { t } = useTranslations()
  const styles = useGetLocatorOrderingMethodsTemplate()
  const { methods, enabledMethods } = useGetOrderingMethod()
  const firm = useEoValue(FIRM_SELECTOR)
  const filter = useEoValue(LOCATOR_INPUT_FILTER_SELECTOR('orderingMethods'))
  const setFirmFilter = useSetEoState(LOCATOR_FILTERS_STATE)

  const handleChange = (event: SyntheticEvent, value: OrderingMethodEnum | null) => {
    setFirmFilter((state: FilterState[]) => setFilters(state, { type: 'orderingMethods', value }))
  }

  useEffect(() => {
    if (!enabledMethods.length || !firm) {
      return
    }

    setFirmFilter((state: FilterState[]) => (
      setFilters(state, { type: 'orderingMethods', value: enabledMethods[0].value })
    ))
  }, [ firm ])

  return (
    <Tabs
      id='locator__delivery__methods_tabs'
      value={filter ? filter.value : null}
      onChange={handleChange}
      displayNullTab={false}
      nullTabLabel={t('locator.filters.all')}
      sx={styles.getTabsSx()}
    >
      {methods.map((method: EnableableOrderingMethod, index: number) => (
        <LocatorDeliveryFilterMethod
          key={index}
          value={method.value}
          displayDisabledOrderingMethod={displayDisabledOrderingMethods}
          disabled={!method.disabled}
        />
      ))}
    </Tabs>
  )
}

export default LocatorDeliveryFilterMethods
