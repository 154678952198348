import getLocatorShowOrderingMethodsFirstTemplateService
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/GetLocatorShowOrderingMethodsFirstTemplateService'
import StylesLocatorShowOrderingMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorShowOrderingMethodsFirstTemplate = (): StylesLocatorShowOrderingMethodsFirstServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorShowOrderingMethodsFirstTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorShowOrderingMethodsFirstTemplate
