import { Firm } from '@eo-storefronts/eo-core'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Paper } from '@mui/material'
import FirmTimeslots from '~/src/components/firms/FirmTimeslots'
import LocatorFirmCardActions from '~/src/components/locator/locator-firm-card/locator-firm-card-actions'
import LocatorFirmCardImage from '~/src/components/locator/locator-firm-card/locator-firm-card-image'
import useGetLocatorFirmCardTemplate
  from '~/src/components/locator/locator-firm-card/styles/useGetLocatorFirmCardTemplate'
import Divider from '~/src/components/mui-wrappers/Divider'
import Title from '~/src/components/mui-wrappers/Title'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useState } from 'react'
import useLocationUrl from '~/src/hooks/locator/useLocationUrl'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  firm: Firm,
}

const LocatorFirmCard = ({ firm }: Props) => {
  const language = useEoValue(LANGUAGE_STATE)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const styles = useGetLocatorFirmCardTemplate()
  const { push } = useRouterPush()
  const { getHref } = useLocationUrl()

  const handleOnClick = () => {
    setIsLoading(true)
    push(getHref(firm))
  }

  return (
    <Paper
      className='locator__firm__card_container'
      sx={styles.getCardContainerSx()}
      onClick={handleOnClick}
    >
      <Box
        sx={{
          display: 'contents'
        }}
      >
        <LocatorFirmCardImage firm={firm}/>

        <Box
          className='locator__firm__card__content'
          sx={styles.getCardContentSx()}
        >
          <Box
            className='locator__firm__card_info'
            sx={styles.getCardInfoSx()}
          >
            <Box
              sx={{
                display: 'contents'
              }}
            >
              <Title
                variant='h5'
                className='eo-ellipsis'
                sx={styles.getCardInfoTitleSx()}
              >
                {firm?.name[language] ?? ''}
              </Title>
              <Box sx={styles.getCardInfoTypographySx()}>
                <LocationOnIcon/>
                <Box
                  component='span'
                  className='eo-ellipsis'
                  sx={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  {firm.address.street}, {firm.address.zipcode} {firm.address.locality}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            className='locator__firm__card_timetable'
            sx={styles.getCardTimetableSx()}
          >
            <Divider/>

            <FirmTimeslots
              firm={firm}
              grouped
            />
          </Box>

          <Box
            className='locator__firm__card_actions'
            sx={styles.getCardActionsSx()}
          >
            <LocatorFirmCardActions
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              firm={firm}
              href={getHref(firm)}/>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default LocatorFirmCard
