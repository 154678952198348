import { AutocompleteRenderInputParams, CircularProgress } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import LocatorGeoPositionFilter from '~/src/components/locator/locator-geo-position-filter'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

interface Props extends AutocompleteRenderInputParams {
  isLoading: boolean,
}

const AutocompleteRenderInput = ({ isLoading, ...params }: Props) => {
  const { t } = useTranslations()

  return (
    <TextField
      {...params}
      placeholder={t('locator.fillYourAddress')}
      fullWidth
      InputProps={{
        ...params.InputProps,
        endAdornment: <>
          {isLoading && (
            <CircularProgress
              disableShrink
              variant='indeterminate'
              size='1.25rem' />
          )}
          {params.InputProps.endAdornment}
        </>,
        startAdornment: <LocatorGeoPositionFilter />
      }}
    />
  )
}

export default AutocompleteRenderInput
