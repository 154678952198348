import { useTranslations } from '~/src/hooks/useTranslations'
import { HTMLAttributes, SyntheticEvent, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import {
  autocompleteClasses,
  AutocompleteRenderInputParams,
  debounce,
  formControlClasses,
  SxProps
} from '@mui/material'
import useGetPlacePredictions from '~/src/hooks/google-maps/useGetPlacePredictions'
import useGetPlaceDetail from '~/src/hooks/google-maps/useGetPlaceDetail'
import AutocompleteRenderInput from '~/src/components/address/address-autocomplete-using-google/AutocompleteRenderInput'
import AutocompleteRenderOption
  from '~/src/components/address/address-autocomplete-using-google/AutocompleteRenderOption'
import { Constants } from '~/src/helpers/constants'
import { useEoValue } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE, GoogleMapsApiState } from '~/src/stores/google-maps-api'

interface Props {
  AutocompleteProps?: {
    sx?: SxProps,
  },
  noOptionsText?: string,

  onAddressSelected(address: google.maps.GeocoderResult | null): void,
}

const AddressAutocompleteUsingGoogle = ({
  noOptionsText,
  onAddressSelected,
  AutocompleteProps
}: Props) => {
  const [ inputValue, setInputValue ] = useState('')
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const { isLoaded } = useEoValue<GoogleMapsApiState>(GOOGLE_MAPS_API_STATE)
  const { t } = useTranslations()
  const { fetch: fetchPlaces, options, setOptions, value, setValue } = useGetPlacePredictions()
  const { fetch: fetchPlaceDetail } = useGetPlaceDetail()

  const _showLoader = () => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
    }, Constants.JS_LOADER_PROCESS_TIME)
  }

  const _handleGetOptionLabel = (option: string | google.maps.places.AutocompletePrediction) => {
    return typeof option === 'string' ? option : option.description
  }

  const _handleOnChange = (event: SyntheticEvent, newValue: google.maps.places.AutocompletePrediction | null) => {
    if (!newValue) {
      return
    }

    _showLoader()
    setOptions([ newValue, ...options ])
    setValue(newValue)
    void fetchPlaceDetail(newValue.place_id, onAddressSelected)
  }

  const _handleOnInputChange = debounce((event: SyntheticEvent, newInputValue: string) => {
    if (newInputValue.length < 3) {
      return
    }

    setInputValue(newInputValue)
  }, Constants.DEBOUNCE_TIME * 2)

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [ value ] : [])
      return
    }

    void fetchPlaces(inputValue)
  }, [ value, inputValue ])

  if (!isLoaded) {
    return null
  }

  return (
    <Autocomplete
      id='google-places-autocomplete'
      autoComplete
      filterSelectedOptions
      filterOptions={(x) => x}
      options={options}
      value={value}
      noOptionsText={noOptionsText ?? t('locator.addressAutocompleteEmpty')}
      sx={{
        width: '100%',
        [`.${formControlClasses.root}`]: {
          backgroundColor: 'transparent'
        },
        [`.${autocompleteClasses.endAdornment}`]: {
          top: 'auto'
        },
        ...AutocompleteProps?.sx ?? {}
      }}
      getOptionLabel={_handleGetOptionLabel}
      onChange={_handleOnChange}
      onInputChange={_handleOnInputChange}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <AutocompleteRenderInput isLoading={isLoading} {...params} />
      )}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: google.maps.places.AutocompletePrediction) =>
        <AutocompleteRenderOption
          option={option}
          {...props}
          key={option.place_id} />
      } />
  )
}

export default AddressAutocompleteUsingGoogle
