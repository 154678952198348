import { TabProps } from '@mui/material'
import useGetLocatorOrderingMethodTemplate
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/useGetLocatorOrderingMethodTemplate'
import Tab from '~/src/components/mui-wrappers/styled/tab'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends TabProps {
  value: string,
  displayDisabledOrderingMethod: boolean,
  disabled: boolean,
}

const LocatorDeliveryFilterMethod = ({ displayDisabledOrderingMethod, disabled, value, ...otherProps }: Props) => {
  const styles = useGetLocatorOrderingMethodTemplate()
  const { t } = useTranslations()

  if (!displayDisabledOrderingMethod && disabled) {
    return null
  }

  return (
    <Tab
      value={value}
      disabled={disabled}
      label={t(`locator.filters.${value}`)}
      sx={styles.getTabSx()}
      {...otherProps}
    />
  )
}

export default LocatorDeliveryFilterMethod
