import { SxProps } from '@mui/material'
import StylesLocatorOrderingMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorOrderingMethodsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorOrderingMethodsServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorOrderingMethodsServiceInterface {
  public getTabsSx(): SxProps {
    return {}
  }
}
