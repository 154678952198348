import { Firm, OrderingMethodEnum, OrderingMethodNames } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  isOrderingMethodAllowed(method: OrderingMethodEnum): boolean,
}

const useIsOrderingMethodAllowed = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)

  const isOrderingMethodAllowed = (method: OrderingMethodEnum): boolean => {
    if (!firm?.locations?.length) {
      return false
    }

    return firm.locations.some((firm: Firm) =>
      Object.keys(firm.settings.orderingMethods).includes(method)
      && firm.settings.orderingMethods[method as unknown as OrderingMethodNames] !== null
    )
  }

  return { isOrderingMethodAllowed }
}

export default useIsOrderingMethodAllowed
