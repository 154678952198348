import getTabPanelTemplateService
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab-panel/styles/GetTabPanelTemplateService'
import StylesTabPanelServiceInterface
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab-panel/styles/StylesTabPanelServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTabPanelTemplate = (): StylesTabPanelServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getTabPanelTemplateService(pageStyle, muiTheme)
}

export default useGetTabPanelTemplate
