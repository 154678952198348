import { paperClasses, SxProps } from '@mui/material'
import StylesLocatorShowOrderingMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-ordering-methods-first/styles/StylesLocatorShowOrderingMethodsFirstServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorShowOrderingMethodsFirstServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorShowOrderingMethodsFirstServiceInterface {
  public getContainerSx(headerHeight: number): SxProps {
    return {
      height: `calc(100vh - ${headerHeight}px)`,
      boxShadow: '14px 0px 13px 8px rgba(0,0,0,0.1)',
      position: 'relative',
      [this.muiTheme.breakpoints.down('md')]: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 20
      },
      'img#brand_shop_image': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        filter: 'blur(15px)',
        transform: 'scale(1.10)'
      }
    }
  }

  public getInnerContainerSx(): SxProps {
    return {
      position: 'absolute',
      p: 2,
      height: '100%',
      width: '100%',
      display: 'grid',
      gridTemplateRows: 'minmax(max-content, 1fr) max-content 1fr',
      gridTemplateColumns: '100%',
      gap: 4,
      zIndex: 2,
      [`.${paperClasses.root}`]: {
        width: '100%'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: 'unset',
        px: 2,
        pt: `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(2)})`
      }
    }
  }

  public getTopSectionSx(): SxProps {
    return {
      alignSelf: 'end',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      pl: 2,
      width: '100%'
    }
  }

  public getBottomSectionSx(): SxProps {
    return {}
  }

  public getLocatorCurrentFirmLogo(): SxProps {
    return {
      maxWidth: '70%',
      maxHeight: '6rem'
    }
  }

  public getComponentSx(): Record<string, unknown> {
    return {}
  }
}
