import StylesTabListServiceInterface
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab-list/styles/StylesTabListServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabListServiceStyleTwo extends TemplateServiceStyleBase implements StylesTabListServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      display: 'flex',
      flexDirection: 'row'
    }
  }
}
