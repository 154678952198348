import StylesLocatorOrderingMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorOrderingMethodServiceInterface'
import StylesLocatorOrderingMethodServiceStyleOne
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorOrderingMethodServiceStyleOne'
import StylesLocatorOrderingMethodServiceStyleTwo
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorOrderingMethodServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorOrderingMethodTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorOrderingMethodServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorOrderingMethodServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorOrderingMethodServiceStyleOne(muiTheme)
  }
}

export default getLocatorOrderingMethodTemplateService
