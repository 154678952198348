import { OrderingMethodEnum } from '@eo-storefronts/eo-core'
import useIsOrderingMethodAllowed from '~/src/hooks/firm/useIsOrderingMethodAllowed'
import { EnableableOrderingMethod } from '~/src/types/EnableableOrderingMethod'

interface ReturnsType {
  methods: EnableableOrderingMethod[],
  enabledMethods: EnableableOrderingMethod[],
  disabledMethods: EnableableOrderingMethod[],
}

const useGetOrderingMethod = (): ReturnsType => {
  const orderingMethods = Object.keys(OrderingMethodEnum).filter((key: string | number) => isNaN(Number(key)))
  const { isOrderingMethodAllowed } = useIsOrderingMethodAllowed()

  const _mapOrderingMethods = (): EnableableOrderingMethod[] => {
    return orderingMethods.map((orderingMethod: string) => ({
      value: orderingMethod as OrderingMethodEnum,
      disabled: isOrderingMethodAllowed(orderingMethod as OrderingMethodEnum)
    }))
  }

  return {
    methods: _mapOrderingMethods(),
    disabledMethods: _mapOrderingMethods().filter((method: EnableableOrderingMethod) => !method.disabled),
    enabledMethods: _mapOrderingMethods().filter((method: EnableableOrderingMethod) => method.disabled)
  }
}

export default useGetOrderingMethod
