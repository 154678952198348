import getLocatorOrderingMethodsTemplateService
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/GetLocatorOrderingMethodsTemplateService'
import StylesLocatorOrderingMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorOrderingMethodsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorOrderingMethodsTemplate = (): StylesLocatorOrderingMethodsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorOrderingMethodsTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorOrderingMethodsTemplate
