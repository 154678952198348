import { useEffect, useState } from 'react'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetSubHeaderHeight from '~/src/hooks/layout/useGetSubHeaderHeight'

interface ReturnsType {
  height: number,
  getHeight(includeSubheader?: boolean): number,
}

const useGetHeaderHeight = (): ReturnsType => {
  const layout = useEoValue(LAYOUT_STATE)
  const { height: headerHeight } = useGetDOMNodeProperties('layout_header')
  const { height: subHeaderHeight } = useGetSubHeaderHeight()
  const [ height, setHeight ] = useState<number>(0)

  const getHeight = (includeSubheader = layout.subHeader.display): number => {
    let calculatedHeight = layout.header.display ? headerHeight : 0

    if (includeSubheader) {
      calculatedHeight += subHeaderHeight
    }

    return calculatedHeight
  }

  useEffect(() => {
    setHeight(getHeight())
  }, [
    headerHeight,
    subHeaderHeight,
    layout.subHeader.display
  ])

  return {
    height,
    getHeight
  }
}

export default useGetHeaderHeight
