import StylesTabPanelServiceStyleOne
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab-panel/styles/StylesTabPanelServiceStyleOne'
import StylesTabPanelServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab-panel/styles/StylesTabPanelServiceStyleTwo'
import StylesTabServiceInterface
  from '~/src/components/mui-wrappers/styled/ordering-methods/tab/styles/StylesTabServiceInterface'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabPanelTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabPanelServiceStyleTwo(muiTheme)
    default:
      return new StylesTabPanelServiceStyleOne(muiTheme)
  }
}

export default getTabPanelTemplateService
