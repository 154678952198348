import { Firm, Id } from '@eo-storefronts/eo-core'
import { ChangeEvent, useEffect } from 'react'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, checkoutFormInitialState, CheckoutFormState } from '~/src/stores/checkout'
import { LOCATOR_FILTERED_FIRMS_SELECTOR } from '~/src/stores/firms'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { useFetchCatalog } from '~/src/hooks/catalog/useFetchCatalog'
import { MenuItem } from '@mui/material'
import LocatorFirmsDropdownListItem
  from '~/src/components/locator/locator-firms-dropdown-list/LocatorFirmsDropdownListItem'

const LocatorFirmsDropdownList = () => {
  const { t } = useTranslations()
  const firms = useEoValue(LOCATOR_FILTERED_FIRMS_SELECTOR)
  const { fetch: fetchCategories } = useFetchCatalog()
  const setCheckoutFormState = useSetEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const [ locatorStateValue, setLocationSelected ] = useEoState<LocatorState>(LOCATOR_STATE)

  const setIsLoading = (isLoading: boolean) => {
    setLocationSelected((state: LocatorState) => ({
      ...state,
      isLoading
    }))
  }

  const resetCheckoutFormState = () => {
    const initialState: CheckoutFormState = checkoutFormInitialState()

    setCheckoutFormState((state: CheckoutFormState) => ({
      ...initialState,
      orderingMethod: {
        ...initialState.orderingMethod,
        method: state.orderingMethod.method
      }
    }))
  }

  const selectFirm = (firm: Firm | undefined) => {
    resetCheckoutFormState()

    if (!firm) {
      setLocationSelected((state: LocatorState) => ({
        ...state,
        firmSelected: undefined
      }))
      return
    }

    setIsLoading(true)
    setLocationSelected({ firmSelected: firm, isLoading: false })
    fetchCategories(firm.id).then(() => setIsLoading(false))
  }

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFirmId: Id | undefined = event.target.value ? JSON.parse(event.target.value) : undefined
    const firm: Firm | undefined = firms.find((firm: Firm) => firm.id === selectedFirmId)

    selectedFirmId && selectFirm(firm)
  }

  useEffect(() => {
    if (firms?.length !== 1) {
      return
    }

    selectFirm(firms[0])
  }, [])

  return (
    <TextField
      fullWidth
      select
      disabled={locatorStateValue.isLoading}
      variant='filled'
      value={locatorStateValue.firmSelected?.id || -1}
      onChange={handleOnChange}
    >
      <MenuItem
        value={-1}
        disabled
      >
        {t('locator.pickup.firmSelectorPlaceholder')}
      </MenuItem>
      {firms?.map((firm: Firm) => (
        <MenuItem
          key={firm.id}
          value={firm.id}
        >
          <LocatorFirmsDropdownListItem firm={firm} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default LocatorFirmsDropdownList
